.VacanciesPage {
  .Vacancies {
    margin-bottom: 160px;
  }
}

.VacanciesPage__vacanciesTitle {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 645;
  line-height: 40px;
  letter-spacing: -0.96px;
}
