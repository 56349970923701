.ContactsPage__cards {
  margin-bottom: 160px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
}

.ContactsPage__card {
  padding: 24px;
  border-radius: 24px;
  background: rgba(19, 8, 43, 0.03);
}

.ContactsPage__card__title {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}

.ContactsPage__card__value {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: -0.72px;
}

.ContactsPage__card__social {
  a {
    text-decoration: none;
  }
}

.ContactsPage__card__social__item {
  color: #5f32df;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: -0.72px;
  display: flex;
  align-items: center;
  .Icon {
    margin-right: 12px;
  }
}

@media (max-width: 1000px) {
  .ContactsPage__cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 700px) {
  .ContactsPage__cards {
    grid-template-columns: 1fr;
  }
}
