.Footer {
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 24px;
}

.Footer__column {
  flex: 1;
  > a {
    color: #5f32df;
    font-size: 16px;
    font-weight: 467;
    line-height: 24px;
    text-decoration: none;
    letter-spacing: -0.48px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .Icon {
      margin-right: 8px;
    }
  }
}

.Footer__column__title {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-weight: 550;
  line-height: 24px;
  letter-spacing: -0.48px;
  text-transform: uppercase;
  margin-bottom: 16px;
  &.company {
    text-transform: none;
    color: #000;
  }
}

.Footer__contacts__item {
  margin-bottom: 8px;
}

.Footer__contacts__item__name {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-weight: 467;
  line-height: 24px;
  letter-spacing: -0.48px;
}

.Footer__contacts__item__value {
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: 467;
  line-height: 24px;
  letter-spacing: -0.48px;
}

.Footer__contacts__item__email {
  a {
    text-decoration: none;
    color: #5f32df;
    font-size: 16px;
    font-weight: 467;
    line-height: 24px;
    letter-spacing: -0.48px;
  }
}

@media (min-height: 900px) and (min-width: 1100px) {
  .Footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 32px !important;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .Footer__column.contacts {
    order: 4;
    width: 100%;
    flex: auto;
  }
  .Footer__column.company {
    order: 5;
    width: 100%;
    flex: auto;
  }
}

@media (max-width: 600px) {
  .Footer__column.contacts {
    order: 1;
  }
  .Footer__column {
    flex: auto;
    width: 100%;
    order: 2;
  }
}
