.SurveyForm {
  margin-top: 87px;
}

.SurveyForm__title {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 645;
  line-height: 36px;
  letter-spacing: -0.48px;
  margin-bottom: 40px;
}

.SurveyFormSelect {
}

.SurveyFormSelect__item__radio {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background: transparent;
  border: 2px solid #00000085;
  position: relative;
}

.SurveyFormSelect__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 8px;
  margin-bottom: 8px;
  &.active {
    .SurveyFormSelect__item__radio {
      border-color: #6b2dee;
      &::after {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50px;
        position: absolute;
        background: #6b2dee;
        top: 3px;
        left: 3px;
      }
    }
  }
}

.SurveyFormSelect__item__label {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 467;
  line-height: 24px;
  letter-spacing: -0.32px;
}

.SurveyInputs {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.SurveyInput {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #e9e8ec;
  background: #fff;
  outline: none;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  width: 360px;
  &::placeholder {
    color: #534c62;
  }
}

textarea.SurveyInput {
  width: 443px;
}

@media (max-width: 900px) {
  .SurveyForm {
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .SurveyInput,
  textarea.SurveyInput {
    width: 100%;
  }
}
