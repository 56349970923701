.VacancyPage__titleAdditional__info {
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin: 24px 0;
}

.VacancyPage__applyButton {
  display: flex;
  align-items: center;
  &::after {
    content: '';
    background-image: url('../../../../public/images/icons/apply.svg');
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 8px;
    background-position: center;
    display: block;
  }
}

.VacancyPage__descriptionTitle {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 645;
  line-height: 40px;
  letter-spacing: -0.96px;
  margin-bottom: 32px;
}

.VacancyPage__descriptionBlock {
  max-width: 920px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 160px;
  ul {
    margin-block-start: 3px;
    padding-inline-start: 23px;
  }
}

.VacancyPage__description {
  margin-bottom: 32px;
}
