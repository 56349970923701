.Vacancies {
  margin-top: 32px;
}

.Vacancies__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
}

.VacancyItem {
  background: rgba(19, 8, 43, 0.03);
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: block;
  text-decoration: none;
  color: #000;
}

.VacancyItem__label {
  background: rgba(95, 50, 223, 0.1);
  border-radius: 8px;
  margin-bottom: 8px;
  color: #5f32df;
  font-size: 16px;
  font-weight: 467;
  line-height: 24px;
  width: max-content;
  letter-spacing: -0.48px;
  padding: 4px 8px;
}

.VacancyItem__title {
  color: #000;
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: -0.72px;
  margin-bottom: 16px;
}

.VacancyItem__info {
  display: flex;
  column-gap: 16px;
}

.VacancyItem__info__item {
  display: flex;
  align-items: center;
}

.VacancyItem__info__item__timeIcon,
.VacancyItem__info__item__remoteIcon {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 4px;
}

.VacancyItem__info__item__timeIcon {
  background-image: url('../../../../public/images/icons/full-part-time.svg');
}

.VacancyItem__info__item__remoteIcon {
  background-image: url('../../../../public/images/icons/remote.svg');
}

@media (max-width: 1100px) {
  .Vacancies__list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 850px) {
  .Vacancies__list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 600px) {
  .Vacancies__list {
    grid-template-columns: 1fr;
  }
}
