.Button {
  padding: 16px 32px;
  color: #fff;
  font-size: 16px;
  font-weight: 645;
  line-height: 28px;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  border: none;

  &.PrimaryButton {
    background: #5f32df;
  }
  &.RoundedButton {
    border-radius: 48px;
    &-2 {
      border-radius: 20px;
    }
  }
  &.SecondaryButton {
    background: rgba(95, 50, 223, 0.1);
    color: #5f32df;
  }
  &.disabled {
    background: gray;
    cursor: default;
  }
  &.small {
    border-radius: 12px;
    padding: 8px 16px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.6px;
    font-weight: 467;
    text-transform: none;
  }
}

.BackButton {
  color: #5f32df;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: transparent;
  &::before {
    content: '';
    background-image: url('../../../../public/images/icons/left.svg');
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 8px;
    background-position: center;
  }
}
