@import './components/header.scss';
@import './components/button.scss';
@import './components/vacancies.scss';
@import './components/footer.scss';
@import './components/icons.scss';
@import './components/survey-progress.scss';
@import './components/survey-form.scss';

@import './pages/main-page.scss';
@import './pages/vacancies-page.scss';
@import './pages/vacancy-page.scss';
@import './pages/contacts-page.scss';
@import './pages/survey.scss';

@font-face {
  font-family: 'Red Hat';
  src: url('../fonts/RedHatDisplay-VariableFont_wght.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Red Hat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f2ff;
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
}

html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.PageContainer {
  padding: 32px;
  background: #f6f2ff;
  position: relative;
  min-height: 100vh;
  @media (max-width: 600px) {
    padding: 16px;
  }
  @media (min-height: 900px) and (min-width: 1100px) {
    padding-bottom: 200px;
  }
}

.PageTitleBlock {
  margin: 200px 0 160px 0;
  @media (max-width: 600px) {
    margin: 158px 0 96px 0;
  }
}

.PageTitle {
  color: #000;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 68px;
  letter-spacing: -1.8px;
  margin-top: 24px;
  margin-bottom: 0;
}

.StaticPage {
  padding-top: 120px;
}
