header {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
}
.Header__inner {
  padding: 32px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background: #f6f2ff;
  display: flex;
}

.Header__menu {
  border-radius: 48px;
  display: flex;
  padding: 0 32px;
  column-gap: 32px;
  width: max-content;
  background: rgba(231, 225, 250, 0.4);
  backdrop-filter: blur(6px);

  z-index: 2;

  a {
    padding: 16px 0;
    color: #000;
    font-size: 16px;
    font-weight: 645;
    line-height: 28px;
    letter-spacing: 0.64px;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    &:hover {
      color: #5f32df;
      &::after {
        content: '';
        position: absolute;
        display: block;
        background-image: url('../../../../public/images/header/blur.svg');
        height: 14px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    &.active {
      color: #5f32df !important;
      border-bottom: 2px solid #5f32df;
    }
  }
}

.Header__logoContainer {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.Header__logo {
  background-image: url('../../../../public/images/logo.png');
  width: 223px;
  height: 44.5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
}

.Header__getButton {
  border-radius: 48px;
  background: #5f32df;
  padding: 16px 32px;
  z-index: 2;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  font-weight: 645;
  line-height: 28px;
  letter-spacing: 0.64px;
  text-transform: uppercase;
}

.Header__menuButton {
  border-radius: 48px;
  background: rgba(231, 225, 250, 0.4);
  backdrop-filter: blur(6px);
  padding: 18px 32px;
  z-index: 2;
  cursor: pointer;
  display: none;
}

.Header__menuIcon {
  background-image: url('../../../../public/images/icons/menu.svg');
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Header__menuBackground {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

header.menuIsOpen {
  .Header__inner {
    background: #f6f2ff;
  }
  .Header__menuBackground {
    display: block;
  }
  .Header__menuIcon {
    background-image: url('../../../../public/images/icons/close.svg');
  }
  .Header__menu {
    background: #f6f2ff;
    position: absolute;
    top: 100px;
    width: 100%;
    left: 0;
    border-radius: 0;
    display: block;
    padding-top: 40px;
    padding-bottom: 96px;
    a {
      display: block;
      color: #000;
      font-size: 24px;
      font-weight: 900;
      text-transform: none;
      line-height: 32px;
      width: max-content;
      &:hover {
        color: #5f32df;
        &::after {
          content: none;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .Header {
    .Header__menuButton {
      display: block;
    }
    .Header__menu {
      display: none;
    }
  }
}

@media (max-width: 850px) {
  .Header {
    .Header__getButton {
      display: none;
    }
    .Header__inner {
      justify-content: flex-end;
    }
    .Header__logoContainer {
      left: 0;
    }
  }
}

@media (max-width: 475px) {
  .Header__logo {
    margin-left: 16px;
  }
}

@media (max-width: 370px) {
  .Header__logo {
    width: 180px;
  }
}
