.MainPage {
  padding: 32px 0;
  background: #f6f2ff;
  position: relative;
  .Footer {
    padding: 0 32px;
  }
}

.MainPage__block {
  padding: 0 32px;
}

.MainPage__topBlock {
  background-image: url('../../../../public/images/main-page/top-block-image.png');
  background-position: right 0px center;
  padding-right: 55%;
  background-repeat: no-repeat;
  background-size: 55%;
  padding: 130px 0;
  margin-top: 100px;
  @media (min-width: 1700px) {
    background-size: 1110px;
  }
}

.MainPage__topBlock__content {
  width: 800px;
  padding: 48px 64px;
}

.MainPage__topBlock__title {
  color: #000;
  font-size: 60px;
  font-weight: 900;
  line-height: 68px;
  letter-spacing: -1.8px;
  margin-bottom: 24px;
}

.MainPage__topBlock__description {
  color: rgba(0, 0, 0, 0.7);
  font-size: 28px;
}

.MainPage__topBlock__getButton {
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  padding: 32px 48px;
  border-radius: 40px !important;
}

.MainPage__title {
  color: #000;
  font-size: 42px;
  font-weight: 900;
  line-height: 53px;
  letter-spacing: -1.26px;
}

.MainPage__services {
  margin-bottom: 130px;
}

.MainPage__services__list {
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
}

.MainPage__services__item {
  border-radius: var(--spacing-6, 24px);
  background: rgba(19, 8, 43, 0.03);
  padding: 24px;
}

.MainPage__services__item__title {
  color: #000;
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: -0.72px;
}

.MainPage__services__item__description {
  color: #000;
  font-size: 16px;
  font-weight: 467;
  line-height: 24px;
  letter-spacing: -0.48px;
}

.MainPage__products {
  margin-bottom: 130px;
}
.MainPage__products__block {
  padding: 24px;
  background: rgba(95, 50, 223, 0.1);
  display: flex;
  column-gap: 16px;
  border-radius: 24px;
  margin-bottom: 32px;
}

.MainPage__products__block__textContent {
  width: 540px;
  min-width: 540px;
}

.MainPage__products__title {
  color: #5f32df;
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: -0.72px;
  margin-bottom: 12px;
}

.MainPage__products__descriprion {
  color: #5f32df;
  font-size: 16px;
  font-weight: 467;
  line-height: 24px;
  letter-spacing: -0.48px;
  width: 440px;
}

.MainPage__products__block__imagesBlock {
  background: #f6f2ff;
  border-radius: 24px;
  padding: 20px;
  height: 402px;
  width: 100%;
  &.deployplace {
    display: flex;
    column-gap: 32px;
    justify-content: center;
  }
}

.MainPage__products__block__images {
  background-image: url('../../../../public/images/main-page/app-images-desktop.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.MainPage__products__block__deployplace {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  flex: 1;
  &.image1 {
    background-image: url('../../../../public/images/main-page/deployplace-app-1.png');
    background-position: right;
  }
  &.image2 {
    background-image: url('../../../../public/images/main-page/deployplace-app-2.png');
    background-position: left;
  }
}

.hidden-anchor {
  visibility: hidden;
  display: block;
  position: relative;
  top: -120px;
}

.MainPage__aboutUs {
  display: flex;
  column-gap: 96px;
  margin-bottom: 130px;
}

.MainPage__aboutUs__block {
  border-radius: 40px;
  background: #5f32df;
  padding: 40px;
  flex: 1;
  position: relative;
}

.MainPage__aboutUs__decoration {
  background-image: url('../../../../public/images/main-page/about-us-dec.svg');
  width: 98px;
  height: 220px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: -26px;
  top: -46px;
}

.MainPage__aboutUs__title {
  color: #fff;
  font-size: 42px;
  font-weight: 900;
  line-height: 53px;
  letter-spacing: -1.26px;
  margin-bottom: 32px;
}

.MainPage__aboutUs__description {
  color: #fff;
  font-size: 32px;
  line-height: 42px;
  padding-right: 35px;
}

.MainPage__approach {
  flex: 1;
}

.MainPage__approach__icon {
  background-image: url('../../../../public/images/main-page/approach-icon.svg');
  width: 80px;
  height: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 16px;
}

.MainPage__approach__title {
  color: #5f32df;
  font-size: 24px;
  font-weight: 645;
  line-height: 32px;
  letter-spacing: -0.72px;
  margin-bottom: 26px;
}

.MainPage__approach__description {
  color: #000;
  font-size: 36px;
  font-weight: 645;
}

.MainPage__vacancies {
  margin-bottom: 160px;
}

.MainPage__vacancies__titleBlock {
  display: flex;
  justify-content: space-between;
}

.MainPage__vacancies__viewAll {
  color: #5f32df;
  text-align: right;
  font-size: 42px;
  font-weight: 900;
  cursor: pointer;
  line-height: 53px;
  letter-spacing: -1.26px;
  text-decoration: none;
}

.MainPage__vacancies__viewAll.mobile {
  display: none;
  text-align: left;
  margin-top: 32px;
  font-size: 32px;
}

@media (min-height: 900px) and (min-width: 1100px) {
  .MainPage {
    padding-bottom: 200px;
  }
}

@media (max-width: 1300px) {
  .MainPage__topBlock {
    margin-top: 0;
    background-position: right -95px center;
  }
  .MainPage__topBlock__content {
    width: 568px;
  }
  .MainPage__products__block__textContent {
    width: 50%;
    min-width: 50%;
  }
  .MainPage__products__descriprion {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .MainPage__services__list {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .MainPage__aboutUs {
    flex-direction: column;
    row-gap: 32px;
  }
}

@media (max-width: 850px) {
  .MainPage__services__list {
    grid-template-columns: 1fr 1fr;
  }
  .MainPage__title {
    font-size: 32px;
    line-height: 40px;
  }
  .MainPage__topBlock {
    padding: 50px 0;
    padding-top: 300px !important;
    background-size: 400px;
    background-position: center top 90px;
  }
  .MainPage__topBlock__title {
    font-size: 32px;
    line-height: 44px;
  }
  .MainPage__topBlock__description {
    font-size: 24px;
  }
  .MainPage__topBlock__content {
    width: 100%;
    padding: 0 16px;
    padding-top: 30px;
  }
  .MainPage__products__block__textContent {
    width: 100%;
  }
  .MainPage__products__title,
  .MainPage__aboutUs__title {
    font-size: 32px;
    line-height: 40px;
  }
  .MainPage__products__block {
    flex-direction: column;
    row-gap: 16px;
  }
  .MainPage__aboutUs__description,
  .MainPage__approach__description {
    font-size: 24px;
    line-height: 32px;
  }
}
@media (max-width: 650px) {
  .MainPage__block {
    padding: 0 16px;
  }
  .MainPage__services__list {
    grid-template-columns: 1fr;
  }

  .MainPage__products__block__images {
    background-image: url('../../../../public/images/main-page/app-images-mobile.png');
  }
  .MainPage__vacancies__viewAll {
    display: none;
  }
  .MainPage__vacancies__viewAll.mobile {
    display: block;
  }
  .MainPage__aboutUs__decoration {
    width: 70px;
    height: 158px;
    right: 2px;
    top: -63px;
  }
  .MainPage__products__block__imagesBlock.deployplace {
    flex-direction: column;
  }
  .MainPage__products__block__deployplace {
    background-position: center !important;
  }
}

@media (max-width: 400px) {
  .MainPage__topBlock {
    padding: 50px 0;
    padding-top: 300px !important;
    background-size: 100%;
    background-position: center top 90px;
  }
}
