.Icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  &.small {
    width: 16px;
    height: 16px;
  }
}

.Icon.linkedin {
  background-image: url('../../../../public/images/icons/linkedin.svg');
}

.Icon.twitter {
  background-image: url('../../../../public/images/icons/twitter.svg');
}

.Icon.facebook {
  background-image: url('../../../../public/images/icons/facebook.svg');
}

.Icon.crunchbase {
  background-image: url('../../../../public/images/icons/crunchbase.svg');
}

.Icon.arrow-left {
  background-image: url('../../../../public/images/icons/arrow-left.svg');
}

.Icon.success {
  background-image: url('../../../../public/images/icons/success.svg');
}

.Icon.survey-ci {
  background-image: url('../../../../public/images/icons/survey-ci.svg');
}

.Icon.survey-contacts {
  background-image: url('../../../../public/images/icons/survey-contacts.svg');
}

.Icon.survey-job {
  background-image: url('../../../../public/images/icons/survey-job.svg');
}

.Icon.survey-position {
  background-image: url('../../../../public/images/icons/survey-position.svg');
}

.Icon.survey-server {
  background-image: url('../../../../public/images/icons/survey-server.svg');
}

.Icon.survey-settings {
  background-image: url('../../../../public/images/icons/survey-settings.svg');
}

.Icon.survey-time {
  background-image: url('../../../../public/images/icons/survey-time.svg');
}

.Icon.survey-users {
  background-image: url('../../../../public/images/icons/survey-users.svg');
}

.Icon.survey-project {
  background-image: url('../../../../public/images/icons/survey-project.svg');
}

.Icon.survey-lang {
  background-image: url('../../../../public/images/icons/survey-lang.svg');
}

.Icon.survey-db {
  background-image: url('../../../../public/images/icons/survey-db.svg');
}
